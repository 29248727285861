<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.marketTemplates') }}</h5>
                <DataTable :value="filteredData" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" v-model:expandedRows="expandedRows" stateStorage="session" stateKey="dt-state-markettemplates">
                    <template #header>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('search.bySport')" :filter="!isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="activeFilter" :options="activeOptions" optionLabel="option" :placeholder="$t('search.byStatus')" :filter="!isMobile()" />
                            </div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column field="name" :header="$t('sportsSettings.name')" :sortable="true"></Column>
                    <Column :header="$t('sportsSettings.sport')">
                        <template #body="{ data }"> {{ sportsDictionary[data.sportId] }}</template></Column
                    >
                    <Column field="createDate" :header="$t('general.createDate')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.createDate) }}</template></Column
                    >
                    <Column field="updateDate" :header="$t('general.updateDate')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.updateDate) }}</template>
                    </Column>
                    <Column :header="$t('sportsSettings.marketsNo')">
                        <template #body="{ data }"> {{ data.marketsList != null ? data.marketsList.length : 0 }}</template></Column
                    >
                    <Column :header="$t('sportsSettings.tournamentsNo')">
                        <template #body="{ data }"> {{ data.tournaments != null ? data.tournaments.length : 0 }}</template></Column
                    >
                    <Column field="isActive" :header="$t('general.status')" :style="{ width: '200px' }">
                        <template #body="{ data }">
                            <span :class="'custom-badge status-' + data.isActive">{{ data.isActive ? $t('general.active') : $t('general.inactive') }}</span>
                        </template>
                    </Column>
                    <template #expansion="slotProps" class="p-text-right">
                        <MarketTemplateDetails :rowData="slotProps.data"></MarketTemplateDetails>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
import MarketTemplateDetails from './Components/MarketTemplateDetails.vue';
export default {
    components: {
        MarketTemplateDetails,
    },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            templates: [],
            SportsSettingsApi: new SportsSettingsApi(),
            loading: true,
            totalPages: 0,
            crtRoute: 'marketTemplates',
            sportsDictionary: {},
            sportsModel: {
                id: '0',
                displayName: 'All',
            },
            activeFilter: { id: '100', option: 'All' },
            activeOptions: [
                { id: '-1', option: 'All' },
                { id: '0', option: 'Inactive' },
                { id: '1', option: 'Active' },
            ],
            sports: [],
            expandedRows: [],
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        editPage() {
            return this.crtRoute + '/edit';
        },
        filteredData() {
            if (this.sportsModel.id === '0') {
                switch (this.activeFilter.id) {
                    case '100': {
                        return this.templates;
                    }
                    case '0': {
                        return this.templates.filter((elem) => elem.isActive == false);
                    }
                    case '1': {
                        return this.templates.filter((elem) => elem.isActive == true);
                    }
                    default: {
                        return this.templates;
                    }
                }
            } else {
                switch (this.activeFilter.id) {
                    case '100': {
                        return this.templates.filter((elem) => elem.sportId == this.sportsModel.id);
                    }
                    case '0': {
                        return this.templates.filter((elem) => elem.sportId == this.sportsModel.id && elem.isActive == false);
                    }
                    case '1': {
                        return this.templates.filter((elem) => elem.sportId == this.sportsModel.id && elem.isActive == true);
                    }
                    default: {
                        return this.templates;
                    }
                }
            }
        },
    },
    mounted() {
        this.SportsSettingsApi.getAllSports().then((responseSport) => {
            this.sports = responseSport.data;
            this.sports.unshift(this.sportsModel);
            this.createSportsDictionary();
            this.SportsSettingsApi.getMarketTemplatesWithTours().then((responseTemplates) => {
                this.templates = responseTemplates.data;
                this.loading = false;
            });
        });
    },
    watch: {
        sportsModel() {
            if (this.sportsModel == '') {
                this.sportsModel = {
                    id: '0',
                    name: 'All',
                };
            }
        },
        activeFilter() {
            if (this.activeFilter == '') {
                this.activeFilter = { id: '100', option: 'All' };
            }
        },
    },
    methods: {
        createSportsDictionary() {
            for (let i = 0; i < this.sports.length; i++) {
                this.sportsDictionary[this.sports[i].id] = this.sports[i].displayName;
            }
        },
    },
};
</script>

<style scoped lang="scss">
</style>